/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'chat-square-text-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 2a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2h-2.5a1 1 0 00-.8.4l-1.9 2.533a1 1 0 01-1.6 0L5.3 12.4a1 1 0 00-.8-.4H2a2 2 0 01-2-2zm3.5 1a.5.5 0 000 1h9a.5.5 0 000-1zm0 2.5a.5.5 0 000 1h9a.5.5 0 000-1zm0 2.5a.5.5 0 000 1h5a.5.5 0 000-1z"/>',
    },
});
